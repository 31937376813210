
import UserFooterNav from '@/components/userFooterNav.vue'
import userApi from '@/http/apis/user'
import { defineComponent, onMounted, computed } from 'vue'
import { Dialog } from 'vant'
import { useStore } from 'vuex'
// import cookies from 'js-cookie'
import { inforConfig, PLATFORM_CODE, USER_DOMAIN_INDEX } from '@/config/inforConfig'
import { Toast } from 'vant'
export default defineComponent({
  name: 'User',
  components: {
    UserFooterNav,
  },
  setup() {
    const store = useStore()
    const userInfo = computed(() => {
      return store.state.userInfo
    })
    // 绑定/解绑微信
    const wechatAuthorize = (type: number) => {
      if (type === 1) {
        // 绑定微信回调地址
        // window.location.href =
        //   USER_SERVER_DOMAIN__NAME +
        //   `/buc/authentication/v1/0/official/official-bind?userCode=${store.state.userInfo.userCode}&redirectUrl=${USER_DOMAIN_INDEX}&platformCode=${PLATFORM_CODE}`
        // 绑定微信
        userApi
          .getWxbind({
            redirectUrl: USER_DOMAIN_INDEX,
            platformCode: PLATFORM_CODE,
          })
          .then((res) => {
            console.log(res, '绑定成功')
            window.location.href = res.data
            // Toast.success('绑定成功')
            // 重新获取用户信息
            // store.dispatch('getUserData')
          })
          .catch((err) => {
            Toast.fail({
              message: err.rt_msg || '绑定失败',
              closeOnClickOverlay: true,
              overlay: true,
              closeOnClick: true,
            })
          })
      } else if (type === 2) {
        // 解除绑定
        userApi
          .getWxUnbind({
            userCode: store.state.userInfo.userCode,
            platformCode: PLATFORM_CODE,
          })
          .then(() => {
            Toast.success('解除绑定成功')
            // 重新获取用户信息
            store.dispatch('getUserData')
          })
          .catch((err) => {
            Toast.fail({
              message: err.rt_msg || '解除绑定失败',
              closeOnClickOverlay: true,
              overlay: true,
              closeOnClick: true,
            })
          })
      }
    }

    // 退出登录
    const loginOut = () => {
      Dialog.confirm({
        title: '确定退出登录？',
        overlay: true,
      }).then(() => {
        store.commit('removeToken')
        store.commit('removeRefreToken')
        // 清除自动登录标识
        localStorage.removeItem('at_ln')
        // 重新跳转用户中心
        window.location.href = inforConfig.USER_DOMAIN_UNLOGIN
        // })
      })
    }

    onMounted(() => {
      // else if (localStorage.getItem('at_ln')) {
      //   console.log('自动登录')
      //   return false
      // }
      if (store.state.auth) {
        // 获取用户信息
        store.dispatch('getUserData')
      } else {
        // 没自动登录标识且没token
        // 跳转用户中心登录
        window.location.href = inforConfig.USER_DOMAIN_LOGIN
      }
    })
    return {
      wechatAuthorize,
      loginOut,
      userInfo,
    }
  },
})
